<template>
	<div class="container-projects">
		<FlipCard
			fileName="crypto-watcher.png"
			title="Crypto Watch"
			link="https://tender-bose-c576c3.netlify.app"
			text="A simple crypto currency price tracker, using Coinbase Pro's Web Hooks API to update prices in real time. Deployed with Netlify."
			techStack="React App"
			github="https://github.com/namfal/cryptocurrency-watch"
		>
		</FlipCard>
		<FlipCard
				fileName="portfolio.png"
				title="Portfolio"
				link="https://namidev.xyz"
				text="My personal portfolio (this) website. Built using Vue, deployed with Netlify."
				techStack="Vue.JS App"
				github="https://github.com/namfal/portfolio"
		>
		</FlipCard>
		<FlipCard
				fileName="roomreports.png"
				title="Room Reports"
				link=""
				text="A project I started to familiarize myself with MEVN stack and deploying to Heroku. This project ended up being much bigger than I initially thought and so it is still a WIP. No longer accessible since Heroku free tier is now a thing of the past..."
				techStack="MEVN App"
		>
		</FlipCard>
	</div>
</template>

<script>
import FlipCard from '../components/FlipCard'

export default {
	name: 'Projects',
	components: { FlipCard }
}
</script>

<style scoped lang="less">
	.container-projects {
		height: 100%;
		overflow: scroll;
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
		flex-wrap: wrap;
	}
</style>
